<template>
  <div class="header">
    <div class="tab-con">
      <div
        v-for="tab in tabs"
        :key="tab.name"
        @click="changeTab(tab)"
        class="tab"
        :class="
          activeView.value === tab.value
            ? 'content-small-semibold tab-selected'
            : 'content-small'
        "
      >
        <img :src="require(`@/assets/images/${tab.icon}`)" alt="" />
        {{ $t(tab.name) }}
      </div>
    </div>
    <div class="archived-info">
      <BaseButton
        :is-loading="actionLoading"
        @click="actionConfirmationModal = true"
        v-if="development.status === 'active'"
        :primary="true"
        :size="'medium'"
        :label="$t('ArchiveVerb')"
      />
      <p
        v-if="development.status === 'archived' && development.archiveDate"
        class="content-small"
      >
        {{ $t("Project") }} {{ $t("Archived").toLowerCase() + ":" }}
        {{ formatDate(development.archiveDate) }}
      </p>
      <BaseButton
        :is-loading="actionLoading"
        v-if="development.status === 'archived'"
        @click="changeStatus('active')"
        :primary="true"
        :size="'medium'"
        :label="$t('Restore')"
      />
    </div>
    <ConfirmationModal
      :text-start-icon="'info.svg'"
      v-if="actionConfirmationModal"
      @onCancel="actionConfirmationModal = false"
      @onConfirm="changeStatus('archived')"
    >
      {{ $t("ConfirmDevelopmentArchiving") }}
    </ConfirmationModal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
import moment from "moment";
import BaseButton from "@/components/common/BaseButton.vue";
import ConfirmationModal from "@/components/common/ConfirmationModal.vue";
export default {
  name: "Header",
  components: {
    BaseButton,
    ConfirmationModal,
  },
  props: ["development"],
  data() {
    return {
      activeView: {
        name: "Units_list",
        value: "units_list",
        icon: "list_view.svg",
      },
      tabs: [
        { name: "Units_list", value: "units_list", icon: "list_view.svg" },
        { name: "Units_grid", value: "units_grid", icon: "grid_view2.svg" },
        { name: "Listings", value: "listings", icon: "objects.svg" },
        { name: "Prospects", value: "prospects", icon: "clients.svg" },
      ],
      actionConfirmationModal: false,
      actionLoading: false,
    };
  },
  created() {
    this.$nextTick(() => {
      if (this.development.isRestricted) {
        this.changeTab(this.tabs[2]);
        this.tabs = [
          { name: "Listings", value: "listings", icon: "objects.svg" },
        ];
      }
    });
  },
  mounted() {},
  watch: {},
  computed: {},
  methods: {
    ...mapActions([
      "changeSidepanelStateProperties",
      "changeSidepanelStateProperty",
    ]),
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    changeTab(tab) {
      this.activeView = tab;
      this.$emit("activeView", tab);
    },
    async changeStatus(status) {
      if (this.actionLoading) return;

      this.actionLoading = true;
      await axios.post(
        `/api/development/${this.development._id}/change-status/${status}`
      );
      this.actionLoading = false;
      this.actionConfirmationModal = false;
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding: 0px 32px 0px 24px;
  background-color: white;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.active-view {
  border-bottom: 1px solid black;
}
.tab-con {
  display: flex;
  gap: 24px;
}
.tab {
  padding: 16px 6.5px 23px 6.5px;
  cursor: pointer;
  gap: 8px;
  height: 100%;
  img {
    width: 16px;
    height: 16px;
  }
}

.tab-selected {
  border-bottom: 2px solid #16afb9;
  background: #e8f7f8;
}
.archived-info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;
}
</style>
