var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "tab-con"
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('div', {
      key: tab.name,
      staticClass: "tab",
      class: _vm.activeView.value === tab.value ? 'content-small-semibold tab-selected' : 'content-small',
      on: {
        "click": function ($event) {
          return _vm.changeTab(tab);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require(`@/assets/images/${tab.icon}`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm.$t(tab.name)) + " ")]);
  }), 0), _c('div', {
    staticClass: "archived-info"
  }, [_vm.development.status === 'active' ? _c('BaseButton', {
    attrs: {
      "is-loading": _vm.actionLoading,
      "primary": true,
      "size": 'medium',
      "label": _vm.$t('ArchiveVerb')
    },
    on: {
      "click": function ($event) {
        _vm.actionConfirmationModal = true;
      }
    }
  }) : _vm._e(), _vm.development.status === 'archived' && _vm.development.archiveDate ? _c('p', {
    staticClass: "content-small"
  }, [_vm._v(" " + _vm._s(_vm.$t("Project")) + " " + _vm._s(_vm.$t("Archived").toLowerCase() + ":") + " " + _vm._s(_vm.formatDate(_vm.development.archiveDate)) + " ")]) : _vm._e(), _vm.development.status === 'archived' ? _c('BaseButton', {
    attrs: {
      "is-loading": _vm.actionLoading,
      "primary": true,
      "size": 'medium',
      "label": _vm.$t('Restore')
    },
    on: {
      "click": function ($event) {
        return _vm.changeStatus('active');
      }
    }
  }) : _vm._e()], 1), _vm.actionConfirmationModal ? _c('ConfirmationModal', {
    attrs: {
      "text-start-icon": 'info.svg'
    },
    on: {
      "onCancel": function ($event) {
        _vm.actionConfirmationModal = false;
      },
      "onConfirm": function ($event) {
        return _vm.changeStatus('archived');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ConfirmDevelopmentArchiving")) + " ")]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }