<template>
  <div class="development-wrapper" v-if="loaded">
    <PropertyDetailPanel
      :refresh="panelRefresher"
      :development="development"
      @openSidepanelAdd="sidepanelAddToggle++"
      @openInteriorPackagesPanel="interiorPanelToggle++"
      @openDetailPanel="detailPanelToggle++"
      @openBuildingPanel="buildingPanelToggle++"
      @openBuildingDetailPanel="
        clickedBuilding = $event;
        buildingDetailPanelToggle++;
      "
      @getDevelopment="getDevelopment()"
    />
    <div class="right-wrapper">
      <Header
        :development="development"
        id="property-header"
        @addBuilding="buildingPanelToggle++"
        @addUnit="addNewUnit()"
        @activeView="activeView = $event"
      />
      <GeneralView
        :key="refresher"
        :table-refresher="tableRefresher"
        :active-view="activeView"
        class="general-view"
        :development="development"
        :add-building="addNewBuildingBool"
        :add-unit="addNewUnitBool"
        :clear-selected-units="clearSelectedUnitsUpdater"
        :sidepanel-add-toggle="sidepanelAddToggle"
        :detail-panel-toggle="detailPanelToggle"
        :interior-panel-toggle="interiorPanelToggle"
        :building-panel-toggle="buildingPanelToggle"
        :building-detail-panel-toggle="buildingDetailPanelToggle"
        :clicked-building="clickedBuilding"
        @closeBuilding="closeNewBuilding()"
        @closeUnit="closeNewUnit()"
        @selectedUnits="setSelectedUnits"
        @getDevelopment="getDevelopment($event)"
      />
    </div>
  </div>
</template>

<script>
import Header from "@/components/PropertyDeveloper/Detail/Header.vue";
import axios from "axios";
import GeneralView from "@/components/PropertyDeveloper/Detail/GeneralView.vue";
import PropertyDetailPanel from "@/components/PropertyDeveloper/Detail/PropertyDetailPanel";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DevelopmentDetail",
  components: {
    Header,
    GeneralView,
    PropertyDetailPanel,
  },
  data() {
    return {
      activeView: { name: "Units_list", value: "units_list" },
      developmentId: "",
      loaded: false,
      refresher: 0,
      addNewBuildingBool: false,
      addNewUnitBool: false,
      selectedUnits: [],
      clearSelectedUnitsUpdater: 0,
      development: null,
      sidepanelAddToggle: 0,
      detailPanelToggle: 0,
      interiorPanelToggle: 0,
      buildingPanelToggle: 0,
      buildingDetailPanelToggle: 0,
      clickedBuilding: null,
      panelRefresher: 0,
      tableRefresher: 0,
    };
  },
  async created() {
    this.updateBackButton();
    this.developmentId = this.$route.params.id;
    await Promise.all([this.getDevelopment()]);
    this.loaded = true;
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
  },
  watch: {},
  methods: {
    ...mapActions(["setShowBackButton", "updateBackButton"]),

    async getDevelopment(event) {
      let response = await axios.get(`/api/development/${this.developmentId}`);
      this.development = response.data;
      if (event === "panel_only") {
        this.panelRefresher++;
      } else if (event === "table_only") {
        this.tableRefresher++;
      } else {
        this.refresher++;
      }
    },
    addNewBuilding() {
      this.addNewBuildingBool = true;
    },
    addNewUnit() {
      this.addNewUnitBool = true;
    },
    closeNewBuilding() {
      this.addNewBuildingBool = false;
    },
    closeNewUnit() {
      this.addNewUnitBool = false;
    },
    setSelectedUnits(value) {
      this.selectedUnits = value;
    },
    clearSelectedUnits() {
      this.clearSelectedUnitsUpdater++;
    },
  },
};
</script>
<style lang="scss" scoped>
#listing-header,
#listing-content {
  transition: width, margin-left 300ms ease-in-out;
}
#property-header {
  margin-left: 1px;
  margin-top: 1px;
}
.development-wrapper {
  overflow-y: hidden;
  overflow-x: hidden;
  height: 91vh;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
}
.right-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-grow: 1;
  width: 100%;
  overflow-x: scroll;
}
.general-view {
  height: 60vh;
  width: 100%;
  flex-grow: 1;
  overflow-y: scroll;
}
</style>
