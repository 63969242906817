var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loaded ? _c('div', {
    staticClass: "development-wrapper"
  }, [_c('PropertyDetailPanel', {
    attrs: {
      "refresh": _vm.panelRefresher,
      "development": _vm.development
    },
    on: {
      "openSidepanelAdd": function ($event) {
        _vm.sidepanelAddToggle++;
      },
      "openInteriorPackagesPanel": function ($event) {
        _vm.interiorPanelToggle++;
      },
      "openDetailPanel": function ($event) {
        _vm.detailPanelToggle++;
      },
      "openBuildingPanel": function ($event) {
        _vm.buildingPanelToggle++;
      },
      "openBuildingDetailPanel": function ($event) {
        _vm.clickedBuilding = $event;
        _vm.buildingDetailPanelToggle++;
      },
      "getDevelopment": function ($event) {
        return _vm.getDevelopment();
      }
    }
  }), _c('div', {
    staticClass: "right-wrapper"
  }, [_c('Header', {
    attrs: {
      "development": _vm.development,
      "id": "property-header"
    },
    on: {
      "addBuilding": function ($event) {
        _vm.buildingPanelToggle++;
      },
      "addUnit": function ($event) {
        return _vm.addNewUnit();
      },
      "activeView": function ($event) {
        _vm.activeView = $event;
      }
    }
  }), _c('GeneralView', {
    key: _vm.refresher,
    staticClass: "general-view",
    attrs: {
      "table-refresher": _vm.tableRefresher,
      "active-view": _vm.activeView,
      "development": _vm.development,
      "add-building": _vm.addNewBuildingBool,
      "add-unit": _vm.addNewUnitBool,
      "clear-selected-units": _vm.clearSelectedUnitsUpdater,
      "sidepanel-add-toggle": _vm.sidepanelAddToggle,
      "detail-panel-toggle": _vm.detailPanelToggle,
      "interior-panel-toggle": _vm.interiorPanelToggle,
      "building-panel-toggle": _vm.buildingPanelToggle,
      "building-detail-panel-toggle": _vm.buildingDetailPanelToggle,
      "clicked-building": _vm.clickedBuilding
    },
    on: {
      "closeBuilding": function ($event) {
        return _vm.closeNewBuilding();
      },
      "closeUnit": function ($event) {
        return _vm.closeNewUnit();
      },
      "selectedUnits": _vm.setSelectedUnits,
      "getDevelopment": function ($event) {
        return _vm.getDevelopment($event);
      }
    }
  })], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }